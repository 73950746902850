import { useEffect, useRef } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from "react-router-dom";

function Login() {
    const { appID } = useParams();
    const navigate = useNavigate();
    const element = useRef(null);

    const onSuccess = () =>{
        navigate(`dashboard`);
    }

    useEffect(()=>{
        if(element.current){
            element.current.onSuccess = onSuccess;
        }
    }, []);

    return (
        <passage-auth app-id={appID} ref={element}></passage-auth>
    );
}

export default Login;
