import React from "react";
import { Routes, Route } from 'react-router-dom';
import Helmet from 'react-helmet';

import Home from "./views/Home";
import Login from "./views/Login";
import Dashboard from "./views/Dashboard";
import Banner from "./components/banner";
import NotFound from "./components/NotFound";
import styles from './styles/App.module.css';

function App() {
  return (
    <div>
        <Helmet>
          <script src={process.env.REACT_APP_PASSAGE_CDN} defer></script>
        </Helmet>
        <Banner/>
        <div className={styles.mainContainer}>
            <Routes>
                <Route path="/" element={<NotFound/>}></Route>
                <Route path="/:appID" element={<Login/>}></Route>
                <Route path="/:appID/dashboard" element={<Dashboard/>}></Route>
            </Routes>
        </div>
        <div className={styles.footer}>
            Learn more with our <a href="https://docs.passage.id">Documentation</a> and <a href="https://github.com/passageidentity">Github</a>.
        </div>
    </div>
  );
}

export default App;
