import { useState, useEffect } from "react";
import { useParams } from 'react-router';
import * as FullStory from "@fullstory/browser";


export function useCurrentUser() {
    const { appID } = useParams();

    const [result, setResult] = useState({
        isLoading: true,
        isAuthorized: false,
    });

    useEffect(() => {
        // WAIT until Passage has been loaded to window
        const interval = setInterval(() => {
            if (window.Passage) {
                clearInterval(interval);
                let cancelRequest = false;
                new window.Passage.Api(appID).currentUser.userInfo().then(userInfo=> {
                    if( cancelRequest ) {
                        return;
                    }
                    if(userInfo === undefined){
                        setResult({
                            isLoading: false,
                            isAuthorized: false,
                        });
                        return;
                    }
                    setResult({
                        isLoading: false,
                        isAuthorized: true,
                    });
                    if(FullStory.isInitialized()){
                        setFSVars(userInfo)
                    }

                }).catch((error) => {
                    console.error(error);
                    setResult({
                        isLoading: false,
                        isAuthorized: false,
                    });
                });
                return () => {
                    cancelRequest = true;
                };
            }
        }, 1);
    }, []);
    return result;
}

//Add additional FS user variables here https://developer.fullstory.com/user-variables
function setFSVars(user) {
    let userVars = {};
    if (user.email) userVars.email = user.email;
    if (user.phone) userVars.phone = user.phone;
    if (Object.keys(userVars).length) FullStory.setUserVars(userVars);
    FullStory.setVars("page", { pageName: "Try Passage Dashboard" });
  }
