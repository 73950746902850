import {useCurrentUser} from '../hooks/useCurrentUser';
import { useParams } from 'react-router';
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import * as FullStory from '@fullstory/browser';
import styles from '../styles/Dashboard.module.css';

function Dashboard() {
    const { appID } = useParams();

    useEffect(() => {
        if (!FullStory.isInitialized()) {
            FullStory.init({
                orgId: '182R1Q',
                devMode: process.env.NODE_ENV !== 'production'
            });
            FullStory.setVars('page', {pageName: 'Example App Login'})
            FullStory.setUserVars({appID})
        }
    },[appID]);

    const navigate = useNavigate();

    const {isLoading, isAuthorized} = useCurrentUser();

    if (isLoading) {
        return null;
    }
    const authorizedBody =
    <>
        <passage-profile app-id={appID}></passage-profile>
    </>

    const unauthorizedBody =
    <>
        You have not logged in and cannot view the dashboard.
        <br/><br/>
        <a href={`/${appID}`} className={styles.link}>Login to continue.</a>
    </>

    function logout(){
        document.cookie = "psg_auth_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        localStorage.removeItem("psg_auth_token");
        navigate(`/${appID}`);
    }

    return (
        <div className={styles.dashboard}>
            <div className={styles.header}>
                { isAuthorized && <div className={styles.logout} onClick={logout}>Logout</div> }
            </div>
            <div className={styles.message}>
                { isAuthorized ? authorizedBody : unauthorizedBody }
            </div>
        </div>
    );

}

export default Dashboard;
