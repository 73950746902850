import styles from '../styles/Banner.module.css';

function Banner() {
    return (
        <div className={styles.mainHeader}>
            <div className={styles.passageLogo}></div>
            <div className={styles.headerText}>Example App</div>
            <div className={styles.spacer}></div>
            <a href="https://console.passage.id/" className={styles.link}>Go to Console</a>
        </div>
    );
}
export default Banner;
