import styles from '../styles/NotFound.module.css';

function NotFound() {
  return (
    <div className={styles.notFound}>
      Not Found
    </div>
  );
}
export default NotFound;
